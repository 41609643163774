import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Axios from 'axios'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { Heading, Box, Button, Text } from 'rebass'
import Hero from "../components/base/Hero"
import Section from '../components/base/Section'
import { Label, Select, Input, Textarea } from '@rebass/forms'

const DEFAULT_STATE = {
  name: '',
  email: '',
  phone: '',
  enquiry: '',
  subject: '',
  description: ''
}

const STATUS_PENDING = 'PENDING'
const STATUS_SUCCESS = 'SUCCESS'
const STATUS_INVALID = 'INVALID'
const STATUS_FAILED = 'FAILED'

const Contact = ({ data: { contact } }) => {
  const [status, setStatus] = useState()
  const [fields, setFields] = useState(DEFAULT_STATE)
  const handleChange = event => {
    const { name, value } = event.target

    setFields({ ...fields, [name]: value })
  }
  const handleSubmit = event => {
    event.preventDefault()

    if (Object.values(fields).some(s => s === '')) {
      setStatus(STATUS_INVALID)

      return
    }

    const payload = {
        user: {
            name: fields.name,
            email: fields.email,
            phone: fields.phone
        },
        ticket: {
            subject: fields.subject,
            description: fields.description,
            custom_fields: [
              { "id": 360000271956, "value": fields.enquiry }
            ]
        }
    }

    setStatus(STATUS_PENDING)

    Axios
      .post(`${process.env.API_URL}/public/zendesk/contact-us`, payload)
      .then(() => {
        setStatus(STATUS_SUCCESS)
        setFields(DEFAULT_STATE)
      })
      .catch(() => setStatus(STATUS_FAILED))
  }

  return (
    <>
      <HelmetDatoCms seo={contact.seoMetaTags} />
      <Hero sx={{ py: 0 }}>
        <Heading as="h1" variant="hero.heading">Contact Us</Heading>
        <Heading as="h2" variant="hero.subheading">Get in contact, we'd love to hear from you.</Heading>
      </Hero>
      <Box mb={6}>
        {contact.sections.map(section => (
          <Box key={section.id} my={5}>
            <Section {...section} />
          </Box>
        ))}
        <Box variant="fixed">
          {status === STATUS_SUCCESS && (
            <Text
              sx={{
                color: 'success',
                bg: '#f2fdf6',
                p: 2
              }}
            >
              Your message has been sent successfully.
            </Text>
          )}
          {status === STATUS_FAILED && (
            <Text
              sx={{
                color: 'error',
                bg: '#ffefef',
                p: 2
              }}
            >
              There was an error sending your message, please try again later.
            </Text>
          )}
          {status === STATUS_INVALID && (
            <Text
              sx={{
                color: 'error',
                bg: '#ffefef',
                p: 2
              }}
            >
              All fields are required.
            </Text>
          )}
          <form onSubmit={handleSubmit}>
            <Box width={[1, 2/3, 1/2]} my={4}>
              <Label htmlFor='name'>Your Name</Label>
              <Input
                id='name'
                type="text"
                name="name"
                value={fields.name}
                onChange={handleChange}
              />
            </Box>
            <Box width={[1, 2/3, 1/2]} my={4}>
              <Label htmlFor='email'>Your Email</Label>
              <Input
                id='email'
                type="email"
                name="email"
                value={fields.email}
                onChange={handleChange}
              />
            </Box>
            <Box width={[1, 2/3, 1/2]} my={4}>
              <Label htmlFor='phone'>Your Phone Number</Label>
              <Input
                id='phone'
                type="text"
                name="phone"
                value={fields.phone}
                onChange={handleChange}
              />
            </Box>
            <Box width={[1, 2/3, 1/2]} my={4}>
              <Label htmlFor='enquiry'>Enquiry</Label>
              <Select
                id='enquiry'
                name='enquiry'
                value={fields.enquiry}
                onChange={handleChange}
              >
                <option />
                <option>Short-stay Landlord</option>
                <option>Landlord</option>
                <option>Rideshare</option>
                <option>Partnership</option>
                <option>Other</option>
              </Select>
            </Box>
            <Box my={4}>
              <Label htmlFor='subject'>Subject</Label>
              <Input
                id='subject'
                type="text"
                name="subject"
                value={fields.subject}
                onChange={handleChange}
              />
            </Box>
            <Box my={4}>
              <Label htmlFor='description'>Your Message</Label>
              <Textarea
                id='description'
                name="description"
                value={fields.description}
                onChange={handleChange}
              />
            </Box>
            <Button
              variant='primary'
              type="submit"
              disabled={status === STATUS_PENDING}
              sx={{ px: 6, display: 'block' }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default Contact

export const query = graphql`
  query ContactQuery {
    contact: datoCmsContact {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      sections {
        ... on DatoCmsDescription {
          id
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContent {
          id
          title
          contentNode {
            childMarkdownRemark {
              html
            }
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`
